import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import FormattedText from '@/atoms/FormattedText';

import Head from '@/components/Head';
import PageHeader from '@/components/PageHeader';
import PageHeaderEditorial from '@/components/PageHeaderEditorial';

export default ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const layout = frontmatter.template;
  const contentTitle = frontmatter.headline || frontmatter.title;

  const contentWrapperProps = {
    w: [1, 1, 10 / 12, 8 / 12],
    o: [0, 0, 1 / 12, 2 / 12],
  };

  const formattedTextProps =
    frontmatter.textSize === 'large' ? { large: true } : {};

  return (
    <>
      <Head
        title={frontmatter.title}
        description={frontmatter.description}
        noindex={frontmatter.noindex}
      />

      <Grid.Container as="section">
        <Grid>
          {layout === 'editorial' && (
            <Grid.Item w={[1, 1, 10 / 12, 2 / 3]}>
              <PageHeaderEditorial title={contentTitle} />
            </Grid.Item>
          )}

          {layout !== 'editorial' && (
            <Grid.Item {...contentWrapperProps}>
              <PageHeader title={contentTitle} />
            </Grid.Item>
          )}

          <Grid.Item {...contentWrapperProps}>
            <FormattedText
              dangerouslySetInnerHTML={{ __html: html }}
              {...formattedTextProps}
            />
          </Grid.Item>
        </Grid>
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        template
        textSize
        slug
        title
        headline
        noindex
      }
    }
  }
`;
