import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Container = styled.header`
  padding-top: ${scale(4)};
  padding-bottom: ${scale(3.5)};

  ${media.md`
    padding-top: ${scale(6)};
    padding-bottom: ${scale(5)};
  `}
`;

export default ({ title, ...props }) => {
  const titleLines = title.split('{br}');

  return (
    <Container {...props}>
      <Heading h="h1Display" as="h1">
        {titleLines.map((line, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={i}>
              {line}
              {i !== titleLines.length - 1 && <br />}
            </span>
          );
        })}
      </Heading>
    </Container>
  );
};
